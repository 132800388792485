import React from 'react';

import Layout from '../components/layout';
import styles from './page.module.css';

export default ({ pageContext: { header, contentLeft, contentRight } }) => {
  return (
    <Layout>
      <div className={styles.root}>
        <h1>{header}</h1>
        <div className={styles.content}>
          {contentLeft && contentLeft.childMarkdownRemark ? <div dangerouslySetInnerHTML={{ __html: contentLeft.childMarkdownRemark.html }} /> : null}
          {contentRight && contentRight.childMarkdownRemark ? <div dangerouslySetInnerHTML={{ __html: contentRight.childMarkdownRemark.html }} /> : null}
        </div>
      </div>
    </Layout>
  );
};
